<template>
  <div>
    <div style="text-align: center;height:60px;line-height: 60px;">热门文章</div>
    <div class="latest-block" v-for="item in latestArticle" :key="item.id">
      <el-image
        class="latest-img"
        :alt="item.title"
        :src="getBackImag(item.cover)"
        fit="cover"
        lazy
      ></el-image>
      <div class="latest-text">
        <div class="latest-title">
          <el-tooltip :content="item.title" placement="top">
            <router-link :to="{name:'articleInfo',params: { articleId: item.id }}">{{item.title}}</router-link>
          </el-tooltip>
        </div>
        <i class="el-icon-date" style="display:block;height:30%;font-size:10px">{{item.createTime}}</i>
      </div>
    </div>
  </div>
</template>

<script>
import { cover } from "@/utils/cover.js";
export default {
  data() {
    return {
      latestArticle: []
    };
  },
  created() {
    this.getArticleLatest();
  },
  computed: {},
  methods: {
    getBackImag(url) {
      return cover(url);
    },
    // 查找最新文章
    async getArticleLatest() {
      let params = {
        pageSize: 10
      };
      const { data: res } = await this.$api.getArticleLatest(params);
      this.latestArticle = res.data.records;
    }
  }
};
</script>


<style lang="less" scoped>
@pcBoder: 1px solid #c1c1c1; //边框
@acolor: #50bae7; //鼠标悬浮颜色
.latest-block {
  width: 99%;
  height: 80px;
  border: 1px solid #c1c1c1;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  /* overflow    : hidden; */
}

.latest-text {
  height: 100%;
  width: 55%;
  text-align: center;
  font-size: 14px;
  flex: 1;
}
.latest-title {
  height: 70%;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  a {
    text-decoration: none;
    color: #000;
    &:hover {
      color: @acolor;
    }
  }
}

.latest-img {
  width: 135px;
  height: 80px;
}
</style>