<template>
  <div class="index-home">
    <div class="classify">
      <div v-for="classify in classifyList" :key="classify.id" @click="searchData(classify.id)">
        <a>{{classify.classifyName}}</a>
      </div>
    </div>
    <div class="index-box">
      <!-- 搜索框 -->
      <div class="index-main">
        <el-input
          placeholder="请输入内容"
          type="text"
          maxlength="80"
          show-word-limit
          v-model="queryInfo.title"
          clearable
          class="search"
          @keyup.enter.native="searchData()"
        >
          <el-button slot="append" icon="el-icon-search" @click="searchData()"></el-button>
        </el-input>
        <!-- 卡片 -->
        <el-card class="index-card">
          <span class="index-tab"  :class="{active: activeLatest}" @click="searchData(null,'create_time desc')">最新文章</span>
          <el-divider direction="vertical"></el-divider>
          <span class="index-tab" :class="{active: activeRank}" @click="searchData(null,'rank_top desc')">热门文章</span>
          <div class="rank-body" v-for="item in rankArticle" :key="item.id">
            <div @click="toArticle(item.id)" class="rank-left">
              <el-image
                class="rank-img"
                :alt="item.title"
                :src="getBackImag(item.cover)"
                fit="cover"
                lazy
              ></el-image>
            </div>
            <div class="rank-right">
              <div class="rank-title">
                <router-link ref="canonical"  :to="{name:'articleInfo',params: { articleId: item.id }}">{{item.title}}</router-link>
              </div>
              <div class="rank-digest">
                <p>{{item.digest}}</p>
              </div>
              <div class="rank-decorate">
                <i class="iconfont decorate">&#xe605;{{item.createTime}}</i>
                <i class="iconfont decorate">&#xe73c;{{item.approveCount}}</i>
                <!-- <i class="el-icon-star-off decorate"></i> -->
                <i class="iconfont decorate">&#xe663;{{item.remarkCount}}</i>
                <i class="iconfont decorate">&#xe64f;{{item.viewCount==null?0:item.viewCount}}</i>
              </div>
            </div>
          </div>
          <!-- 无限滚动 -->
          <div
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="0"
            class="rank-scroll"
          >
            <el-avatar
              class="rank-scroll-img"
              :size="50"
              :src="require('@/assets/img/profile.png')"
              alt="到底部了啊"
            ></el-avatar>
            <span class="rank-scroll-text">{{status}}</span>
          </div>
        </el-card>
      </div>
      <!-- 侧栏 -->
      <div class="index-aside">
        <latest ></latest>
        <div>
          <div class="policy">Copyright©2021知识追寻者.保留所有权利</div>
          <div class="policy">
            <a
              style="text-decoration: none;color:inherit;"
              ref="nofollow"
              href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index"
            >IPC备案:闽ICP备2021002267号</a>
          </div>
          <div class="policy">本站联系邮箱： lsc_106@126.com</div>
          <div class="policy">
            <el-popover placement="bottom" title="微信扫一扫关注" width="160" trigger="hover">
              <img
                style="width: 160px; height: 160px;"
                fit="cover"
                src="@/assets/img/zszxz.jpg"
                class="profile"
              />
              <div slot="reference">官方微信公众号:知识追寻者</div>
            </el-popover>
          </div>
        </div>
      </div>
      <!-- 侧栏结束 -->
    </div>
  </div>
</template>


<script>
// 标志位，避免请求空数据
import { cover } from "@/utils/cover.js";
import latest from "@/components/latest/latest";
export default {
  data() {
    return {
      rankArticle: [],
      busy: false,
      queryInfo: {
        pageNum: 1,
        pageSize: 5,
        classifyId: 0,
        title: "",
        orderby: 'create_time desc'
      },
      status: "努力加载中....",
      classifyList: [],
      activeRank: false,
      activeLatest: true
    };
  },
  components: {
    latest
  },
  created() {
    this.getArticleRank(this.queryInfo);
    this.getArticleClassify();
  },
  computed: {},
  methods: {
    getBackImag(url) {
      return cover(url);
    },
    // 跳转至文章详情页面
    toArticle(id) {
      // return window.origin+'article/'+id
      this.$router.push({ name: "articleInfo", params: { articleId: id } });
    },
    // 搜索文章
    searchData(classifyId,orderby) {
      this.status = "努力加载中....";
      this.queryInfo.pageNum = 0;
      this.rankArticle = [];
      // 分类存在标题置空
      if (classifyId) {
        this.queryInfo.classifyId = classifyId;
        this.queryInfo.title = null;
      }
      // 标题存在，分类置空
      if (this.queryInfo.title) {
        this.queryInfo.classifyId = null;
      }
      // 排序判断
      if(orderby){
        if(orderby=='rank_top desc'){
          this.activeRank = true
          this.activeLatest =false
        }else{
          this.activeRank = false
          this.activeLatest =true
        }
        this.queryInfo.orderby = orderby
      }
      this.loadMore();
    },
    // 无限滚动
    loadMore: function() {
      this.busy = true;
      setTimeout(() => {
        this.queryInfo.pageNum++;
        this.getArticleRank(this.queryInfo);
      }, 500);
    },
    // 获取排名文章
    async getArticleRank(params) {
      if (this.queryInfo.classifyId != 0 && this.queryInfo.classifyId) {
        params.classifyId = this.queryInfo.classifyId;
      } else {
        params.classifyId = null;
      }
      const { data: res } = await this.$api.getArticleRank(params);
      if (res.data.size != 0) {
        this.rankArticle.push.apply(this.rankArticle, res.data.list);
        this.busy = false;
        this.status = "努力加载中....";
      } else {
        this.busy = true;
        this.status = "没有更多了啊!";
      }
    },
    // 文章分类
    async getArticleClassify() {
      let classify = JSON.parse(window.sessionStorage.getItem("classifyList"));
      if (classify) {
        this.classifyList = classify;
      } else {
        const { data: res } = await this.$api.getArticleClassify();
        let all = { classifyName: "全部", id: "0" };
        res.data.unshift(all);
        this.classifyList = res.data;
        window.sessionStorage.setItem("classifyList", JSON.stringify(res.data));
      }
    }
  }
};
</script>

<style scoped>
@import "../assets/css/index.css";
</style>
