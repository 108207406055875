const cover = function(url) {
    if (url) {
        return url;
    } else {
        let imgArray = [
            'https://img.zszxz.com/images/2021/5/54fe5c4de36849d9b1d4e54d9346ff3d.jpg',
            'https://img.zszxz.com/images/2021/5/378716d94afd47529b2475f2e07c04f4.jpg',
            'https://img.zszxz.com/images/2021/5/68800b8aba5142eea71452bd2c36d569.jpg',
            'https://img.zszxz.com/images/2021/5/53087f2c94ad42dfa62c2ffda95947b7.jpg',
            'https://img.zszxz.com/images/2021/5/f6b2e775323b48749bcf51c674f7f66e.jpg',
            'https://img.zszxz.com/images/2021/5/bb2d7242d21d4f75be89705bdb37f27d.jpg',
            'https://img.zszxz.com/images/2021/5/3f64b1aea6fc48fd896de6f4a5b9fa1a.jpg',
            'https://img.zszxz.com/images/2021/5/de5aa21f9fb04e739725037bb9c6b0a7.jpg',
            'https://img.zszxz.com/images/2021/5/9253dc199b1b418ba75b11007b5c2a7e.jpg',
            'https://img.zszxz.com/images/2021/5/8d63a30517124aa691ae22f83a503830.jpg',
            'https://img.zszxz.com/images/2021/5/e573730c483c4fb286d52ff793584524.jpg',
            'https://img.zszxz.com/images/2021/5/92bb68ae173941aca0c498ce7906c74f.jpg',
            'https://img.zszxz.com/images/2021/5/56652b3f8f504325af876207cbfca346.jpg',
            'https://img.zszxz.com/images/2021/5/ed2745a43b234b30af3af32666f6e60e.jpg',
            'https://img.zszxz.com/images/2021/5/e5b06a6dbb2146e5a50d2eedb86db413.jpg',
            'https://img.zszxz.com/images/2021/5/ee9d7131b1294d3497033d6f2cf14219.jpg',
            'https://img.zszxz.com/images/2021/5/847c6073d68c4de7acd2c083ab02191c.jpg',
            'https://img.zszxz.com/images/2021/5/9478cae9f1704679b4cb8e54ba5d6a71.jpg',
            'https://img.zszxz.com/images/2021/5/2d69488ffe484c2b90393367e2fe3e03.jpg',
            'https://img.zszxz.com/images/2021/5/0c3db4cd8e10429faef42e494346123a.jpg',
            'https://img.zszxz.com/images/2021/5/d5fb21854d25470fae98be173cbb13c4.jpg',
            'https://img.zszxz.com/images/2021/5/9782981296c04046b2157878802767b4.jpg',
            'https://img.zszxz.com/images/2021/5/b4235b22ea514ac28bce7ed47bba8d38.jpg',
            'https://img.zszxz.com/images/2021/5/f0e5026c08774e6d94a325aad5fa82ed.jpg',
            'https://img.zszxz.com/images/2021/5/e4205e061b46489081d44ca2ab40033f.jpg',
            'https://img.zszxz.com/images/2021/5/03e76b4a01054e188a96b6de01de9e1f.jpg',
            'https://img.zszxz.com/images/2021/5/6316922843644eceb9e662aa2f87ea37.jpg',
            'https://img.zszxz.com/images/2021/5/ad6de37513aa4e188e82b3e8d2376c64.jpg',
            'https://img.zszxz.com/images/2021/5/76d60072cd8044fbabddb72669ea6b62.jpg',
            'https://img.zszxz.com/images/2021/5/ca6496cb0f2c459b997c2fb31e398f05.jpg'

        ]
        return imgArray[Math.floor((Math.random() * imgArray.length))]
            // return require("@/assets/img/cover.png");
    }
}

export {
    cover
}